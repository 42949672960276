import img from "../assets/logo-full.png";

export default function About(props) {
  return (
    <section id="about" class="about-container">
      <div className="about-header">
        <div className="about-welcome">Welcome To </div>
        <div className="about-title">SmartTech System</div>
        <div className="about-desc">
          The Most Reliable & Durable Elevator Accessories such as Elevator
          Controller Board, Elevator Control Panel, Car Operating Panel, Landing
          Operation Panel, Elevator Automatic Rescue Device etc., Are Offered
          Here.
        </div>
      </div>
      <div className="about-flex-container">
        <div className="about-col">
          <img src={img} />
        </div>
        <div className="about-col">
          We, Smartech System, believe that it is better to choose completely
          reliable options instead of choosing low quality options which might
          cause an accident. Specially, when it comes to elevators, a minor
          defect can cause harm to lives of many. Our company constantly spread
          awareness about disadvantages of manufacturing low quality elevators
          among dealers of the same in the market. This way, we strive to
          safeguard the general public. It is our aim to educate the public as
          well as engineers engaged in manufacturing elevators. To help such
          engineers, we bring forth a top grade assortment of Elevator
          Accessories like Elevator Control Panels, Elevator Controller Board
          and Elevator Logic Board & Other Cards. The high quality of these
          offered accessories meets the expectations of engineers across India.
          Dealers who strive to manufacture the best Elevators always bestow
          their trust on our company as we are renowned for the standardized
          accessories that we bring forth.
        </div>
      </div>
    </section>
  );
}
