import logo from "../assets/logo.png";

const Header = (props) => {
  const links = [];

  return (
    <>
      <header className="header">
        <img src={logo} width={70} height={80} />
        <input type="checkbox" id="toggle" />
        <label for="toggle">
          {" "}
          <img
            className="menu"
            src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjQcRCwNIHXgysCmSjnyxc4peA8hq0NI7gcQvhjIOY9WLa8wr-lshwxZcgveagSi4UoUBJ8muOPfLmpugzjVro7-5Lrpynl-Ngr_cMsKAzPU9CMwjqQLK7ee_6hf0v2FN_tPDTYAeIKYZLQpvARWwM9D-iHbyJ8OwC1Xd-u-nbDw6HiTzUScSxaVd9yEhD3/s2500/menu1.png"
            alt="menu"
          />
        </label>
        <nav class="navigation">
          <ul>
            <li>
              <a href="#about">About us</a>
            </li>
            <li>
              <a href="#contact">Contact us</a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
export default Header;
