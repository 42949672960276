import { useState } from "react";

export default function ContactForm() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [query, setQuery] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = {
      id: Math.random(),
      name,
      phone,
      email,
      query,
    };
    console.log(formData);
    alert("Thanks for your query! We will get back to you soon.");
    setName("");
    setEmail("");
    setPhone("");
    setQuery("");
  };
  return (
    <form onSubmit={submitHandler}>
      <div className="input-box">
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="input-box">
        <input
          type="number"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="input-box">
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="input-box message-box">
        <textarea
          placeholder="Your query!"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        ></textarea>
      </div>
      <div className="button">
        <input type="submit" value="Send Now" />
      </div>
    </form>
  );
}
