import { Carousel } from "antd";
import Banner1 from "../assets/banner-1.jpg";
import Banner2 from "../assets/banner-2.jpg";
import Banner3 from "../assets/banner-3.jpg";
const Banners = () => (
  <section className="banners-container">
    <div className="carousel-container">
      <Carousel arrows infinite={false}>
        <div>
          <img className="carousel-img" src={Banner1} />
        </div>
        <div>
          <img className="carousel-img" src={Banner2} />
        </div>
        <div>
          <img className="carousel-img" src={Banner3} />
        </div>
      </Carousel>
    </div>
  </section>
);
export default Banners;
