import "./App.css";
import About from "./components/About";
import Banners from "./components/Banners";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import ProductList from "./components/ProductList";
import Body from "./layout/Body";
import Header from "./layout/Header";

function App() {
  return (
    <>
      <Header />
      <Body>
        <Banners />
        <About />
        <ProductList />
        <ContactUs />
      </Body>
      <Footer />
    </>
  );
}

export default App;
