import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import ContactForm from "./ContactForm";
const iconStyle = {
  fontSize: "20px",
};
export default function ContactUs() {
  return (
    <section id="contact" className="contact-container">
      <div className="container">
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <HomeOutlined style={iconStyle} />
              <div className="topic">Address</div>
              <div className="text-one">
                Flat No 001, Sai Samarth Apartment,{" "}
              </div>
              <div className="text-two">43, New Subhedar Nagpur 440024</div>
            </div>
            <div className="phone details">
              <PhoneOutlined style={iconStyle} />
              <div className="topic">Phone</div>
              <div className="text-one">+91 9399171039</div>
              <div className="text-two">+91 8793127426 </div>
            </div>
            <div className="email details">
              <MailOutlined style={iconStyle} />
              <div className="topic">Email</div>
              <div className="text-one">ravikant.smartech@gmail.com</div>
              <div className="text-two">info@smartechsystem.com</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">LET'S TALK BUSINESS!</div>
            <p>
              Please take a quick moment to complete this form and a business
              representative will get back to you swiftly
            </p>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}
