import { Card, Row, Col } from "antd";
import { Meta } from "antd/es/list/Item";
import p1 from "../assets/products/p1.jpg";
import p2 from "../assets/products/p2.jpg";
import p3 from "../assets/products/p3.jpg";
import p4 from "../assets/products/p4.jpg";
import p5 from "../assets/products/p5.jpg";
import p6 from "../assets/products/p6.jpg";
import p7 from "../assets/products/p7.jpg";
import p8 from "../assets/products/p8.jpg";

const products = [
  {
    id: 1,
    title: "V3F control panel",
    image: p1,
  },
  {
    id: 2,
    title: "V3F Control Panel with Drive",
    image: p2,
  },
  {
    id: 3,
    title: "ICS- 3500",
    image: p3,
  },
  {
    id: 4,
    title: "ICS-100 Logic Board for Single Speed Elevator",
    image: p4,
  },
  {
    id: 5,
    title: "Control Panel",
    image: p5,
  },
  {
    id: 6,
    title: "Elevator LOP",
    image: p6,
  },
  {
    id: 7,
    title: "V3F with In Built ARD & Drive Fuji",
    image: p7,
  },
  {
    id: 8,
    title: "ICS-900 Logic Board for V3F Elevator",
    image: p8,
  },
];
const productStyle = {
  width: 300,
  padding: 5,
  margin: 10,
};

export default function ProductList(props) {
  return (
    <>
      <section className="product-container">
        <h1 style={{ margin: "auto", textAlign: "center", padding: "20px" }}>
          Our Products
        </h1>
        <div className="product-galary-container">
          {products.map((product) => {
            return (
              <Card
                key={product.id}
                hoverable
                style={productStyle}
                cover={
                  <img
                    alt="example"
                    src={product.image}
                    height={300}
                    width={300}
                  />
                }
              >
                <Meta
                  key={product.id}
                  title={product.title}
                  // description="www.instagram.com"
                />
              </Card>
            );
          })}
        </div>
      </section>
    </>
  );
}
